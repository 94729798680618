import { useState, useCallback, useMemo } from 'react';

export interface WithModalProps {
  open: boolean;
  toggleModal: () => void;
}

interface UseModalResult extends WithModalProps {
  openModal: () => void;
  closeModal: () => void;
}

export const useModal = (defaultOpen = false): UseModalResult => {
  const [open, setOpen] = useState(defaultOpen);

  const toggleModal = useCallback(() => {
    setOpen((currentopen) => !currentopen);
  }, []);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return useMemo(
    () => ({
      open,
      toggleModal,
      openModal,
      closeModal,
    }),
    [open, toggleModal, openModal, closeModal],
  );
};

