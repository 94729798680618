import cn from 'classnames';
import React, { useState, useEffect, useMemo } from 'react';

import { PageHeader } from 'shared/page-header';
import { Table } from 'shared/table';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { ProjectRow } from 'models/project';

import { ProjectActions } from './projects.actions';
import { columns } from './projects.column-props';
import styles from './projects.page.module.scss';
import { useGetProjectsQuery } from './projects.service';
import { projectsActions, projectsSelector } from './projects.slice';

const { setSelectedProjects, setValueForFilter } = projectsActions;

export interface ProjectsPageProps {
  className?: string;
}

export function ProjectsPage({ className }: ProjectsPageProps) {
  const classes = cn(styles.ProjectsPage, className);
  useDocumentTitle('Проекты');
  const dispatch = useAppDispatch();
  const { valueForFilter } = useAppSelector(projectsSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data: projects, isLoading } = useGetProjectsQuery();

  useEffect(() => {
    dispatch(setSelectedProjects([]));
    dispatch(setValueForFilter(''));
  }, []);

  const filteredProjects = useMemo(
    () =>
      valueForFilter.trim()
        ? projects?.filter((project) =>
            project.name.toLocaleLowerCase().includes(valueForFilter.trim().toLocaleLowerCase()),
          )
        : projects,
    [projects, valueForFilter],
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRow: ProjectRow[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProjects(newSelectedRow));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Мои проекты'} extra={<ProjectActions />}>
        <Table<ProjectRow>
          data-onboarding='projects'
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={filteredProjects}
          rowSelection={rowSelection}
          loading={isLoading}
          rowClassName={(project) => (project.onboarding ? 'project-row--onboarding' : '')}
        />
      </PageHeader>
    </PageLayout>
  );
}

