import { TariffItem } from 'models/tariff';
import { TranscriptionType } from 'models/transcription';

export interface TranscriptionSettings {
  type: TranscriptionType;
  priority: TariffItem['priority'];
}

export function createTranscriptionSettings(): TranscriptionSettings {
  return {
    type: 'AUTO',
    priority: null,
  };
}

