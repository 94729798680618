import { notification } from 'antd';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Spin } from 'shared/spin';

import { EditableTitle } from 'components/editable-title';
import { PlayerCurrentTime } from 'components/player';
import { ProjectLayout } from 'components/project-layout';

import { checkNotFoundError, getResponseError } from 'helpers/response';
import { reachGoal } from 'helpers/yandex-metrika';

import { useDocumentTitle } from 'hooks/document-title';
import { useInterviewId } from 'hooks/interview-id';
import { useLeaveBlocker } from 'hooks/leave-blocker';
import { useProjectId } from 'hooks/project-id';

import { Interview } from 'models/interview';
import { Transcription as ITranscription, Transcription, TranscriptionChannel } from 'models/transcription';

import { NotFound } from 'features/not-found';
import { useGetProjectQuery } from 'features/project';

import { usePayTranscriptionMutation } from 'store/services/payments.service';

import { InterviewActions } from './interview.actions';
import { InterviewAdapter } from './interview.adapter';
import styles from './interview.page.module.scss';
import { useEditInterviewMutation, useGetInterviewQuery, useLazyDownloadTranscriptionQuery } from './interview.service';

export interface InterviewPageProps {
  className?: string;
}

export function InterviewPage({ className }: InterviewPageProps) {
  const classes = cn(styles.interviewPage, className);

  const { projectId } = useProjectId();
  const { interviewId } = useInterviewId();
  const [editInterview, { isLoading: isSaving }] = useEditInterviewMutation();

  if (projectId === undefined || interviewId === undefined) {
    return <NotFound />;
  }

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
    isError: isProjectError,
  } = useGetProjectQuery(projectId);
  const {
    data,
    isLoading: isInterviewLoading,
    isFetching: isInterviewFetching,
    error: interviewError,
    isError: isInterviewError,
  } = useGetInterviewQuery({ projectId, id: interviewId });
  const [downloadTranscription, { isLoading: isDownloading }] = useLazyDownloadTranscriptionQuery();
  const [payTranscription, { isLoading: isPaying }] = usePayTranscriptionMutation();
  useDocumentTitle(data?.name || '', project?.name || '');

  // interviewItemKey - костыль для обновления времени фразы, перемонтируем компонент interview-item полностью
  // чтобы время отображалось корректно
  const [interviewItemKey, setInterviewItemKey] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [interview, setInterview] = useState<Interview>();
  const [currentTime, setCurrentTime] = useState<PlayerCurrentTime>();
  const unsavedTranscriptions = useRef<Record<ITranscription['id'], ITranscription>>({});
  const [saveKey, setSaveKey] = useState<number | undefined>();

  useEffect(() => {
    if (data && !isInterviewFetching) {
      setInterview(data);
      // Сбрасывать состояние сохранения надо обязательно после получения новых данных с бэкенда,
      // чтобы исправить показ старого респондента вместо созданного
      setIsUpdating(false);
      setIsBlocking(false);
      unsavedTranscriptions.current = {};
    }
  }, [data, isInterviewFetching]);

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isProjectError && !checkNotFoundError(projectError)) {
      notification.error({
        message: getResponseError(projectError),
        duration: 0,
        key,
      });
    } else if (isInterviewError && !checkNotFoundError(interviewError)) {
      notification.error({
        message: getResponseError(interviewError),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isProjectError, isInterviewError]);

  const updateInterview = <K extends keyof Interview>(key: K, value: Interview[K]) => {
    setInterview((currentInterview) => {
      if (data && currentInterview) {
        const updatedInterview = {
          ...currentInterview,
          [key]: value,
        };
        if (project?.onboarding !== true) {
          compareInterviews(data, updatedInterview, setIsBlocking);
        }
        return updatedInterview;
      }
    });
  };

  const onChangeTranscription = (transcription: ITranscription) => {
    // Отредактированные трансрибации хранятся в отдельном ref, чтобы избежать лишних рендеров
    // Обновление транскрибаций в интервью происходит при сохранении

    const prevPreparedTranscriptions = getPreparedTranscriptions();
    const prevActiveChannelIds = getActiveChannelIds(prevPreparedTranscriptions);
    const prevTextsAmount = getChannelsTextsAmount(prevActiveChannelIds, prevPreparedTranscriptions);

    unsavedTranscriptions.current = {
      ...unsavedTranscriptions.current,
      [transcription.id]: transcription,
    };

    const preparedTranscriptions = getPreparedTranscriptions();
    const activeChannelIds = getActiveChannelIds(preparedTranscriptions);

    const textsAmount = getChannelsTextsAmount(activeChannelIds, preparedTranscriptions);

    if (interview?.transcriptions) {
      setActiveChannelIds(activeChannelIds);
    }

    // если изменилось количество и с бэкенда приходит время,
    // значит нам нужно обновлять для отображения актуального времени
    if (prevTextsAmount !== textsAmount && interview?.transcriptions[0].texts[0].start) {
      updateInterview('transcriptions', preparedTranscriptions);
      setInterviewItemKey((prev) => prev + 1);
    }

    const { transcribeStatus } = transcription;
    // Если проект онбординга или транскрипт в статусах in_progress/in_progress_full,
    // тогда не надо блокировать закрытие страницы без сохранения
    if (project?.onboarding || transcribeStatus === 'in_progress' || transcribeStatus === 'in_progress_full') {
      return;
    }
    setIsBlocking(true);
  };

  const getPreparedTranscriptions = () => {
    return interview ? interview?.transcriptions.map((t) => unsavedTranscriptions.current[t.id] ?? t) : [];
  };

  const onReplicaPlayClick = (currentTime: PlayerCurrentTime) => {
    setCurrentTime(currentTime);
  };

  const onChangeChannels = (channels: TranscriptionChannel[]) => {
    updateInterview('channels', channels);
  };

  const onRemoveTranscription = () => {
    updateInterview('transcriptions', []);
    queueMicrotask(() => {
      setSaveKey(Date.now());
    });
  };

  const onDownloadTranscription = useCallback(async (transcription: Transcription) => {
    try {
      await downloadTranscription({
        projectId,
        interviewId,
        interviewName: interview?.name,
        id: transcription.id,
      }).unwrap();
      reachGoal('action_download_transcription');
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  }, []);

  const onPayTranscription = useCallback(async (transcription: Transcription, callback: () => void) => {
    try {
      const { status, link } = await payTranscription({
        transcriptionId: transcription.id,
      }).unwrap();
      if (status === 'paid') {
        callback();
        reachGoal('action_pay_trancsription_paid');
        notification.success({
          message: `Транскрибация файла началась`,
        });
      } else if (status === 'need_payment' && link) {
        reachGoal('action_pay_trancsription_need_payment');
        window.location.href = link;
      } else {
        notification.error({
          message: 'Что-то пошло не так...',
        });
      }
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  }, []);

  const onSave = async () => {
    if (interview) {
      // Показывать спиннер сохранения нужно только при наличии созданных респондентов,
      // чтобы обновить в редакторе созданных локально респондентов с id меньше 0 на сохраненных в БД с реальным id
      if (interview.channels.find((channel) => channel.id < 0)) {
        setIsUpdating(true);
      }
      const transcriptions = getPreparedTranscriptions();
      try {
        const preparedInterview = {
          ...interview,
          transcriptions,
        };
        await editInterview({ projectId, interview: preparedInterview }).unwrap();
        reachGoal('action_save_interview');
        notification.success({
          message: 'Интервью сохранено',
        });
        // Здесь сбрасывать состояние сохранения не надо, это делается выше в useEffect
      } catch (error) {
        setIsUpdating(false);
        notification.error({
          message: getResponseError(error),
        });
      }
    }
  };

  useEffect(() => {
    if (saveKey !== undefined) {
      onSave();
    }
  }, [saveKey]);

  const { setIsBlocking } = useLeaveBlocker(onSave);

  const [activeChannelIds, setActiveChannelIds] = useState<Set<TranscriptionChannel['id']>>(() =>
    getActiveChannelIds(interview?.transcriptions),
  );
  useEffect(() => {
    setActiveChannelIds(getActiveChannelIds(interview?.transcriptions));
  }, [interview?.transcriptions]);

  if (checkNotFoundError(projectError) || checkNotFoundError(interviewError)) {
    return <NotFound />;
  }

  return (
    <>
      <ProjectLayout
        className={classes}
        projectId={projectId}
        headerSlot={
          <EditableTitle
            isLoading={isProjectLoading}
            to={routes.interviews(projectId)}
            image={project?.image}
            title={project?.name}
            actionSlot={
              <Button onClick={onSave} loading={isSaving} disabled={isInterviewLoading || isInterviewError}>
                Сохранить
              </Button>
            }
          />
        }
      >
        <div className={styles.box}>
          <EditableTitle
            isLoading={isInterviewLoading}
            image={interview?.image}
            imageType='interview'
            onAddImage={(image) => updateInterview('image', image)}
            onRemoveImage={() => updateInterview('image', null)}
            title={interview?.name}
            onChangeTitle={(name) => updateInterview('name', name)}
            size='small'
            actionSlot={
              <InterviewActions
                disabled={interview ? interview.transcriptions.length !== 0 : true}
                interviewId={interview?.id}
              />
            }
          />
          {isUpdating && <Spin className={styles.spin} />}
          {!isUpdating && project && interview && interview.transcriptions.length > 0 && (
            <InterviewAdapter
              currentTime={currentTime}
              onReplicaPlayClick={onReplicaPlayClick}
              interviewItemKey={interviewItemKey}
              interview={interview}
              onChange={onChangeTranscription}
              onRemove={onRemoveTranscription}
              onDownload={onDownloadTranscription}
              isDownloading={isDownloading}
              onPay={onPayTranscription}
              isPaying={isPaying}
              onChangeChannels={onChangeChannels}
              questionsList={project?.questions || []}
              activeChannelIds={activeChannelIds}
            />
          )}
        </div>
      </ProjectLayout>
    </>
  );
}

const compareInterviews = debounce(function (
  originalInterview: Interview,
  updatedInterview: Interview,
  setIsBlocking: (isBlocking: boolean) => void,
): void {
  setIsBlocking(!isEqual(originalInterview, updatedInterview));
},
750);

function getActiveChannelIds(transcriptions?: ITranscription[]): Set<TranscriptionChannel['id']> {
  return new Set(
    transcriptions ? transcriptions.flatMap((transcription) => transcription.texts.map(({ channel }) => channel)) : [],
  );
}

function getChannelsTextsAmount(activeChannelIds: Set<TranscriptionChannel['id']>, transcriptions?: ITranscription[]) {
  return Array.from(activeChannelIds).reduce(
    (acc, channelId) => acc + getChannelTextsAmount(channelId, transcriptions),
    0,
  );
}

function getChannelTextsAmount(channelId: TranscriptionChannel['id'], transcriptions?: ITranscription[]): number {
  return transcriptions
    ? transcriptions.reduce(
        (acc, transcription) =>
          transcription.texts.reduce((acc2, text) => (text.channel === channelId ? acc2 + 1 : acc2), 0),
        0,
      )
    : 0;
}

