import { MailOutlined, LockOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Form, FormItem } from 'shared/form';
import { Input, InputPassword } from 'shared/input';
import { Title, Paragraph } from 'shared/typography';

import { AuthLayout } from 'components/auth-layout';

import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';
import { reachGoal } from 'helpers/yandex-metrika';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './sign-up.page.module.scss';
import { SignUpRequest, useSignUpMutation } from './sign-up.service';

export interface SignUpPageProps {
  className?: string;
}

export function SignUpPage({ className }: SignUpPageProps) {
  const classes = cn(styles.signUpPage, className);
  useDocumentTitle('Регистрация');
  const navigate = useNavigate();
  const [signUp, { isLoading }] = useSignUpMutation();
  const [error, setError] = useState<null | string>(null);

  const onFinish = async (values: SignUpRequest) => {
    setError(null);
    try {
      await signUp(values).unwrap();
      reachGoal('action_signup');
      navigate(routes.welcome(), { replace: true });
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <AuthLayout
      className={classes}
      footerSlot={
        <Paragraph size='small'>
          Регистрируясь, вы подтверждаете, что принимаете нашу{' '}
          <Link to={routes.privacy()}>Политику&nbsp;конфиденциальности</Link> и{' '}
          <Link to={routes.termsOfService()}>Условия&nbsp;использования</Link> платформы Qulap
        </Paragraph>
      }
    >
      <Title>Добро пожаловать!</Title>
      <Form
        onFinish={onFinish}
        autoComplete='off'
        error={error}
        actionSlot={
          <Button htmlType='submit' loading={isLoading}>
            Зарегистрироваться
          </Button>
        }
      >
        <FormItem name='email' rules={[validateRules.required('email'), validateRules.email()]}>
          <Input addonBefore={<MailOutlined />} />
        </FormItem>
        <FormItem name='password' rules={[validateRules.required('пароль')]}>
          <InputPassword addonBefore={<LockOutlined />} />
        </FormItem>
      </Form>
    </AuthLayout>
  );
}

