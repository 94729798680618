import { declOfNum } from 'helpers/decl-of-num';

export function formatPriceWithRuble(price: number): string {
  return `${price} ${declOfNum(price, ['рубль', 'рубля', 'рублей'])}`;
}

export function formatPricePerHour(price: number): string {
  return `${formatPriceWithRuble(price)}/час`;
}

export function formatPricePerMinute(price: number): string {
  return `${formatPriceWithRuble(price)}/минута`;
}

