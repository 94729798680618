import cn from 'classnames';
import React, { useEffect } from 'react';
import { Link, Location, useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { PageHeader } from 'shared/page-header';
import { Paragraph } from 'shared/typography';

import { PageLayout } from 'components/page-layout';

import { useDocumentTitle } from 'hooks/document-title';
import { useUser } from 'hooks/user';

import styles from './welcome.page.module.scss';

interface LocationWithState extends Location {
  state: {
    url?: string;
  };
}

export interface WelcomePageProps {
  className?: string;
}

export function WelcomePage({ className }: WelcomePageProps) {
  const classes = cn(styles.welcomePage, className);
  useDocumentTitle('Добро пожаловать');
  const navigate = useNavigate();
  const { userPermissions } = useUser();
  const location = useLocation() as LocationWithState;

  useEffect(() => {
    if (userPermissions) {
      const {
        demoInterview: { projectId, interviewId },
        hasFreeAuto,
      } = userPermissions;
      if (hasFreeAuto && projectId !== undefined && interviewId !== undefined) {
        navigate(routes.interview(projectId, interviewId), {
          replace: true,
          state: { isFirstVisit: true },
        });
      } else {
        const url = location.state?.url;
        navigate(url || routes.home(), {
          replace: true,
        });
      }
    }
  }, [userPermissions]);

  return (
    <PageLayout className={classes}>
      <PageHeader title={'Добро пожаловать!'}>
        <Paragraph>Если не произошел редирект на страницу, то нажмите на ссылку ниже.</Paragraph>
        <Link to={routes.home()}>
          <Button href={routes.home()}>Открыть страницу</Button>
        </Link>
      </PageHeader>
    </PageLayout>
  );
}

