import { QuestionCircleTwoTone } from '@ant-design/icons';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Hint } from 'components/hint';

import styles from './base-menu.module.scss';

export interface BaseMenuItemProps {
  label: ReactNode;
  to?: string;
  onboardingSelector?: string;
  hideActivity?: boolean;
  hintContent?: ReactNode;
}

export interface BaseMenuProps {
  className?: string;
  items: BaseMenuItemProps[];
}

export function BaseMenu({ className, items }: BaseMenuProps) {
  const classes = cn(styles.baseMenu, className);

  return (
    <ul className={classes}>
      {items.map(({ label, to, onboardingSelector, hideActivity, hintContent }) => (
        <li className={styles.item} key={`${label}-${to}`}>
          {to && (
            <NavLink
              data-onboarding={onboardingSelector}
              className={({ isActive }) =>
                cn(styles.link, {
                  [styles.link_active]: isActive && !hideActivity,
                })
              }
              to={to}
              end={true}
            >
              {label}
            </NavLink>
          )}
          {hintContent && (
            <Hint
              content={hintContent}
              trigger='click'
              zIndex={900}
              maxWidth={500}
              placement='bottom'
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
            >
              <span className={styles.hint}>
                {label} <QuestionCircleTwoTone />
              </span>
            </Hint>
          )}
        </li>
      ))}
    </ul>
  );
}

