import { RadioChangeEvent, notification } from 'antd';
import React, { useMemo, useState } from 'react';

import { Alert } from 'shared/alert';
import { Form, FormItem, useForm, useWatch } from 'shared/form';
import { Modal } from 'shared/modal';
import { Radio, RadioItem } from 'shared/radio';
import { Spin } from 'shared/spin';
import { Text } from 'shared/typography';

import { Hint } from 'components/hint';
import { TariffModal } from 'components/tariff-modal';

import { formatPriceWithRuble } from 'helpers/price-formatter';
import { getResponseError } from 'helpers/response';
import { reachGoal } from 'helpers/yandex-metrika';

import { WithModalProps } from 'hooks/modal';
import { useUser } from 'hooks/user';

import { Transcription, translateTranscriptionType } from 'models/transcription';
import {
  createTranscriptionSettings,
  TranscriptionSettings as ITranscriptionSettings,
} from 'models/transcription-settings';
import { UserPermissions } from 'models/user';

import { useTranscribeFileMutation } from 'store/services/file.service';
import { useGetTariffQuery } from 'store/services/tariff.service';

import styles from './transcription-settings.module.scss';

export interface TranscriptionSettingsProps extends WithModalProps {
  id?: Transcription['id'];
  price?: Transcription['price'];
  onChangeStatusAndType: (status: Transcription['transcribeStatus'], type: Transcription['type']) => void;
}

export function TranscriptionSettings({
  id,
  price,
  open,
  toggleModal,
  onChangeStatusAndType,
}: TranscriptionSettingsProps) {
  const { userPermissions } = useUser();

  const [form] = useForm<ITranscriptionSettings>();
  const type = useWatch('type', form);

  const [error, setError] = useState<null | string>(null);

  const [transcribeFile, { isLoading: isTranscribing }] = useTranscribeFileMutation();
  const onStartTranscribe = async () => {
    if (id) {
      try {
        const values = form.getFieldsValue();
        await transcribeFile({ id, settings: values }).unwrap();
        const { type } = values;
        onChangeStatusAndType('in_progress', type);
        toggleModal();
        const goalMapper = {
          AUTO: 'action_start_auto_transcribe',
          MANUAL: 'action_start_manual_transcribe',
        };
        reachGoal(goalMapper[type]);
        notification.success({
          message: `${translateTranscriptionType(type)} транскрибация файла началась`,
        });
      } catch (error) {
        setError(getResponseError(error));
      }
    }
  };

  const { data: tariff } = useGetTariffQuery();

  const onChangeType = (event: RadioChangeEvent) => {
    setError(null);
    if (event.target.value === 'AUTO') {
      form.setFieldValue('priority', tariff?.auto.priority);
    } else if (event.target.value === 'MANUAL') {
      form.setFieldValue('priority', tariff?.manual.priority_4.priority);
    }
  };

  const restrictions = useMemo(() => checkRestrictions(type, userPermissions), [type, userPermissions]);

  const onCancel = () => {
    reachGoal('action_close_start_transcribe');
    toggleModal();
  };

  return (
    <Modal
      className={styles.transcriptionSettings}
      width={400}
      open={open}
      title='Параметры транскрибации'
      onCancel={onCancel}
      okButtonProps={{ loading: isTranscribing, disabled: restrictions.isExhauested }}
      onOk={onStartTranscribe}
      okText='Продолжить'
      destroyOnClose={true}
    >
      <Form form={form} initialValues={createTranscriptionSettings()} autoComplete='off' error={error}>
        <FormItem name='type'>
          <Radio onChange={onChangeType}>
            <RadioItem value={'AUTO'}>
              {translateTranscriptionType('AUTO')}{' '}
              <Hint content='Транскрибации машинными алгоритмами. Обрабатывает час аудио около 5-10 минут. Качество транскрипта зависит от качества аудио.' />
            </RadioItem>
            <RadioItem value={'MANUAL'}>
              {translateTranscriptionType('MANUAL')}{' '}
              <Hint content='Создается заботливыми руками транскрибаторов. Получите транскрипцию в течении 1-3х суток, в зависимости от выбранного тарифа. Точно разделим говорящих в аудио и разберемся даже с плохим качеством записи.' />
            </RadioItem>
          </Radio>
        </FormItem>
        <FormItem noStyle={true} shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => (
            <FormItem
              name='priority'
              label={<>Стоимость транскрибации {tariff && <TariffModal tariff={tariff} hideText={true} />}</>}
            >
              {tariff && price ? (
                <Radio>
                  {getFieldValue('type') === 'AUTO' && (
                    <>
                      <RadioItem value={tariff.auto.priority}>
                        {formatPriceWithRuble(price.auto.price)}{' '}
                        <Text type='secondary'>({tariff.auto.name.toLowerCase()})</Text>
                      </RadioItem>
                      {price.auto.price === 0 && (
                        <FormItem>
                          <Text type='secondary'>
                            Вам доступна бесплатная автоматическая транскрибация длительностью до 10 минут. У записей
                            длиннее будут транскрибированы первые 10 минут.
                          </Text>
                        </FormItem>
                      )}
                    </>
                  )}
                  {getFieldValue('type') === 'MANUAL' && (
                    <>
                      <RadioItem value={tariff.manual.priority_4.priority}>
                        {formatPriceWithRuble(price.manual.priority_4.price)}{' '}
                        <Text type='secondary'>({tariff.manual.priority_4.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_3.priority}>
                        {formatPriceWithRuble(price.manual.priority_3.price)}{' '}
                        <Text type='secondary'>({tariff.manual.priority_3.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_2.priority}>
                        {formatPriceWithRuble(price.manual.priority_2.price)}{' '}
                        <Text type='secondary'>({tariff.manual.priority_2.name.toLowerCase()})</Text>
                      </RadioItem>
                      <RadioItem value={tariff.manual.priority_1.priority}>
                        {formatPriceWithRuble(price.manual.priority_1.price)}{' '}
                        <Text type='secondary'>({tariff.manual.priority_1.name.toLowerCase()})</Text>
                      </RadioItem>
                    </>
                  )}
                </Radio>
              ) : (
                <Spin />
              )}
            </FormItem>
          )}
        </FormItem>
        {!restrictions.isAllowed && !restrictions.isExhauested && (
          <FormItem>
            <Alert message={allowedAlerts[type]} type='warning' />
          </FormItem>
        )}
        {restrictions.isExhauested && (
          <FormItem>
            <Alert message={exhaustedAlerts[type]} type='error' />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
}

function checkRestrictions(type?: ITranscriptionSettings['type'], permissions?: UserPermissions) {
  switch (type) {
    case 'AUTO':
      return {
        isAllowed: permissions?.autoTranscribeAllowed,
        isExhauested: permissions?.autoTranscribeExhausted,
      };
    case 'MANUAL':
      return {
        isAllowed: permissions?.manualTranscribeAllowed,
        isExhauested: permissions?.manualTranscribeExhausted,
      };
    default:
      return {
        isAllowed: true,
        isExhauested: true,
      };
  }
}

const allowedAlerts: { [key in ITranscriptionSettings['type']]: string } = {
  AUTO: 'Вы можете загрузить 1 файл для пробного автоматического транскрипта',
  MANUAL: 'Вы можете загрузить 1 файл длительностью до 10 минут для пробного ручного транскрипта',
};

const exhaustedAlerts: { [key in ITranscriptionSettings['type']]: string } = {
  AUTO: 'Вы уже исчерпали лимит пробного автоматического транскрипта',
  MANUAL: 'Вы уже исчерпали лимит пробного ручного транскрипта',
};

