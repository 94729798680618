import { useMemo } from 'react';
import { Location, useLocation } from 'react-router-dom';

export interface LocationWithFirstVisitState extends Location {
  state: {
    isFirstVisit?: boolean;
  };
}

export interface WithModalProps {
  isFirstVisit: boolean;
}

export const useFirstVisit = (): WithModalProps => {
  const location = useLocation() as LocationWithFirstVisitState;

  const isFirstVisit = location.state?.isFirstVisit ?? false;

  return useMemo(
    () => ({
      isFirstVisit,
    }),
    [isFirstVisit],
  );
};

