import { FileRelatedId, FileRelatedType } from 'models/file';
import { Image, ImageRelatedType } from 'models/image';
import { Interview } from 'models/interview';
import { Project } from 'models/project';
import { Transcription } from 'models/transcription';

const host = '';
const prefix = 'api/v1';

export const paths = {
  login: () => [host, prefix, 'auth/login'].join('/'),
  logout: () => [host, prefix, 'auth/logout'].join('/'),
  signUp: () => [host, prefix, 'auth/register'].join('/'),
  forgotPassword: () => [host, prefix, 'auth/password/email'].join('/'),
  resetPassword: () => [host, prefix, 'auth/password/reset'].join('/'),
  profile: () => [host, prefix, 'profile'].join('/'),
  profilePermissions: () => [host, prefix, 'profile-permissions'].join('/'),
  projects: () => [host, prefix, 'projects'].join('/'),
  project: (projectId: Project['id']) => [host, prefix, 'projects', projectId].join('/'),
  interviews: (projectId: Project['id']) => [host, prefix, 'projects', projectId, 'interviews'].join('/'),
  interview: (projectId: Project['id'], interviewId: Interview['id']) =>
    [host, prefix, 'projects', projectId, 'interviews', interviewId].join('/'),
  downloadTranscription: (
    projectId: Project['id'],
    interviewId: Interview['id'],
    transcriptionId: Transcription['id'],
  ) => [host, prefix, 'projects', projectId, 'interviews', interviewId, 'download', transcriptionId].join('/'),
  images: (imageType: ImageRelatedType) => [host, prefix, 'images', imageType].join('/'),
  image: (imageId: Image['id']) => [host, prefix, 'images', imageId].join('/'),
  files: (fileType: FileRelatedType, id: FileRelatedId, isTranscription?: boolean) =>
    [host, prefix, 'files', fileType, id, isTranscription ? 'audio' : ''].filter(Boolean).join('/'),
  cloudLinks: (fileType: FileRelatedType, id: FileRelatedId, isTranscription?: boolean) =>
    [host, prefix, 'files', 'link', fileType, id, isTranscription ? 'audio' : ''].join('/'),
  transcribeFile: (id: FileRelatedId) => [host, prefix, 'files', id, 'transcribe'].join('/'),
  report: (projectId: Project['id']) => [host, prefix, 'projects', projectId, 'report'].join('/'),
  sharedReport: (uuid: string) => [host, prefix, 'projects', 'report', uuid].join('/'),
  downloadReport: (projectId: Project['id']) => [host, prefix, 'projects', projectId, 'report', 'download'].join('/'),
  shareReport: (projectId: Project['id']) => [host, prefix, 'projects', projectId, 'report', 'share'].join('/'),
  companies: () => [host, prefix, 'companies'].join('/'),
  tariff: () => [host, prefix, 'profile', 'tariff'].join('/'),
  paymentStatus: (code: string) => [host, prefix, 'payments', 'status', code].join('/'),
  makePayment: () => [host, prefix, 'payments', 'make'].join('/'),
  payTranscription: (transcriptionId: Transcription['id']) =>
    [host, prefix, 'payments', 'demo', transcriptionId].join('/'),
  billingPayments: () => [host, prefix, 'payments'].join('/'),
  billingFiles: () => [host, prefix, 'files'].join('/'),
};

