import { DEFAULT_EDITOR_VALUE } from 'components/editor';

import { LocalDate } from 'models/date';
import { Image } from 'models/image';
import { Question } from 'models/question';

export interface NewProject {
  name: string;
  image: Image | null;
  description: string;
  questions: Question[];
}

export interface Project extends NewProject {
  id: number;
  demo: boolean;
  onboarding: boolean;
}

export interface ProjectRow extends Project {
  interviews: number;
  createdAt: LocalDate;
  updatedAt: LocalDate;
}

export function createNewProject(): NewProject {
  return {
    name: 'Название проекта',
    image: null,
    description: JSON.stringify(DEFAULT_EDITOR_VALUE),
    questions: [],
  };
}

