import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Form, FormItem, useForm } from 'shared/form';
import { Row, Col } from 'shared/grid';
import { Input } from 'shared/input';
import { Title } from 'shared/typography';

import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';
import { reachGoal } from 'helpers/yandex-metrika';

import { EditableUserFields } from 'models/user';

import { useEditProfileMutation } from './profile.service';

export interface ProfileFormProps {
  isLoading: boolean;
  profile?: EditableUserFields;
  isFirstVisit: boolean;
}

export function ProfileForm({ isLoading, profile, isFirstVisit }: ProfileFormProps) {
  const navigate = useNavigate();
  const [editProfile, { isLoading: isSaving }] = useEditProfileMutation();
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const isDirty = useRef(false);

  const [form] = useForm<EditableUserFields>();

  useEffect(() => {
    if (profile && !isDirty.current) {
      form.resetFields();
    }
  }, [profile]);

  const onFinish = async (values: EditableUserFields) => {
    setError(null);
    setSuccess(null);
    try {
      await editProfile(values).unwrap();
      setSuccess('Профиль был обновлен');
      isDirty.current = false;
      if (isFirstVisit) {
        reachGoal('action_save_profile_first');
        navigate(routes.projects());
      } else {
        reachGoal('action_save_profile');
      }
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={profile}
      autoComplete='off'
      error={error}
      success={success}
      actionSlot={
        <Button htmlType='submit' disabled={isLoading} loading={isSaving}>
          {isFirstVisit ? 'Сохранить и продолжить' : 'Сохранить'}
        </Button>
      }
      onFieldsChange={() => {
        isDirty.current = true;
      }}
    >
      <Row>
        <Col span={COL_SPAN}>
          <FormItem name='firstName' label='Имя' rules={[validateRules.required('имя')]}>
            <Input disabled={isLoading} />
          </FormItem>
        </Col>
        <Col span={COL_SPAN}>
          <FormItem name='lastName' label='Фамилия' rules={[validateRules.required('фамилия')]}>
            <Input disabled={isLoading} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={COL_SPAN}>
          <FormItem name='company' label='Название компании' rules={[validateRules.required('название компании')]}>
            <Input disabled={isLoading} />
          </FormItem>
        </Col>
        <Col span={COL_SPAN}>
          <FormItem name='position' label='Должность'>
            <Input disabled={isLoading} />
          </FormItem>
        </Col>
      </Row>
      <Title level={3}>Контакты</Title>
      <Row>
        <Col span={COL_SPAN}>
          <FormItem name='email' rules={[validateRules.required('email'), validateRules.email()]}>
            <Input disabled={true} addonBefore={<MailOutlined />} placeholder='Email' />
          </FormItem>
        </Col>
        <Col span={COL_SPAN}>
          <FormItem name='phone'>
            <Input disabled={isLoading} addonBefore={<PhoneOutlined />} placeholder='+0 (000) 000 00 00' />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

const COL_SPAN = 12;

