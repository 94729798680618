import { Image } from 'models/image';

export type TranscriptionType = 'AUTO' | 'MANUAL';

export interface Transcription {
  id: number;
  name: string;
  url: string;
  transcribeStatus: 'not_started' | 'in_progress' | 'in_progress_full' | 'done' | 'error';
  type?: TranscriptionType;
  texts: TranscriptionText[];
  price: {
    auto: TranscriptionPrice;
    manual: {
      priority_1: TranscriptionPrice;
      priority_2: TranscriptionPrice;
      priority_3: TranscriptionPrice;
      priority_4: TranscriptionPrice;
    };
  };
  demo: boolean;
}

export interface NewTranscriptionChannel {
  name: string;
  image: Image | null;
}

export interface TranscriptionChannel extends NewTranscriptionChannel {
  id: number;
}

export interface TranscriptionText {
  id: number;
  channel: TranscriptionChannel['id'];
  text: string;
  start?: number;
  end?: number;
}

interface TranscriptionPrice {
  price: number;
  name: string;
  priority: number | null;
}

export function createNewTranscriptionChannel(): NewTranscriptionChannel {
  return {
    name: '',
    image: null,
  };
}

export function translateTranscriptionType(type: TranscriptionType): string {
  switch (type) {
    case 'AUTO':
      return 'Автоматическая';
    case 'MANUAL':
      return 'Ручная';
    default:
      throw new Error(`Неизвестный тип ${type}`);
  }
}

export function translateTranscriptionTypeIntoAdverb(type: TranscriptionType): string {
  switch (type) {
    case 'AUTO':
      return 'Автоматически';
    case 'MANUAL':
      return 'Вручную';
    default:
      throw new Error(`Неизвестный тип ${type}`);
  }
}

