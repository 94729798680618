import { LogoutOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import cn from 'classnames';
import React from 'react';

import { Button } from 'shared/button';

import { getResponseError } from 'helpers/response';

import styles from './logout.button.module.scss';
import { useLogoutMutation } from './logout.service';

export interface LogoutButtonProps {
  className?: string;
}

export function LogoutButton({ className }: LogoutButtonProps) {
  const classes = cn(styles.logoutButton, className);
  const [logout, { isLoading }] = useLogoutMutation();

  const onClick = async () => {
    try {
      await logout().unwrap();
      // Нужно обновлять страницу, чтобы сбросить кэш RTK query
      window.location.reload();
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  return (
    <Button
      className={classes}
      type='default'
      onClick={onClick}
      danger={true}
      loading={isLoading}
      icon={<LogoutOutlined />}
    >
      Выйти из аккаунта
    </Button>
  );
}

