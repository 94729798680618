import { notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { Button } from 'shared/button';
import { Space } from 'shared/space';

import { Hint } from 'components/hint';

import { getResponseError } from 'helpers/response';
import { reachGoal } from 'helpers/yandex-metrika';

import { useFileDialog } from 'hooks/file-dialog';
import { useFirstVisit } from 'hooks/first-visit';
import { useModal } from 'hooks/modal';
import { useAppSelector } from 'hooks/store';

import { Interview } from 'models/interview';

import { useAddCloudLinkMutation, useAddFileMutation } from 'store/services/file.service';
import { uploadProgressSelector } from 'store/slices/file.slice';

import { InterviewAddFile } from './interview.add-file';

export interface InterviewActionsProps {
  disabled: boolean;
  interviewId?: Interview['id'];
}

export function InterviewActions({ disabled, interviewId }: InterviewActionsProps) {
  const { isFirstVisit } = useFirstVisit();

  const [isCloudChosen, setIsCloudChosen] = useState(false);

  const { open, toggleModal, closeModal } = useModal();
  const [addFile, { isLoading: isFileAdding }] = useAddFileMutation();
  const [addCloudLink, { isLoading: isLinkAdding }] = useAddCloudLinkMutation();
  const { uploadProgress } = useAppSelector(uploadProgressSelector);

  useEffect(() => {
    if (isFirstVisit && !disabled) {
      // Открываем модальное окно добавления файла, если это первый визит и файл не загружен
      toggleModal();
    }
  }, [isFirstVisit, disabled]);

  const onAddFileClick = () => {
    toggleModal();
    reachGoal('action_open_add_file');
  };

  const onCloudUpload = async (link: string) => {
    if (!interviewId) {
      return;
    }
    closeModal();

    try {
      await addCloudLink({
        type: 'interviews',
        id: interviewId,
        link,
        wasInvalidateTags: true,
        isTranscription: true,
      }).unwrap();
      reachGoal('action_add_file_cloud');
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const uploadFile = async (file: File) => {
    if (!interviewId) {
      return;
    }
    closeModal();

    try {
      await addFile({
        type: 'interviews',
        id: interviewId,
        file,
        wasInvalidateTags: true,
        isTranscription: true,
      }).unwrap();
      reachGoal('action_add_file_local');
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const { openFileDialog } = useFileDialog(uploadFile, '.wav, .mp3, .ogg, .m4a, .mp4, .avi, .mpeg, .webm, .mov');

  return (
    <Space size='small'>
      <InterviewAddFile
        open={open}
        toggleModal={toggleModal}
        openFileDialog={openFileDialog}
        onCloudUpload={onCloudUpload}
        isCloudChosen={isCloudChosen}
        setIsCloudChosen={(chosen) => setIsCloudChosen(chosen)}
      />
      <Hint content='Файлы до 1ГБ. Аудио: wav, mp3, ogg, m4a. Видео: mp4, avi, mpeg, webm, mov.' />
      <Button
        data-onboarding='interview-upload'
        type='default'
        onClick={onAddFileClick}
        loading={isFileAdding || isLinkAdding}
        disabled={disabled}
        title={disabled ? 'Нельзя загружать больше одного транскрипта' : undefined}
      >
        <Space size='small'>
          {isFileAdding && <span>{uploadProgress}%</span>}
          Добавить файл для транскрибации
        </Space>
      </Button>
    </Space>
  );
}

