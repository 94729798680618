import { paths } from 'api/paths';

import { PaymentStatus } from 'models/payment';
import { SuccessResponse } from 'models/response';
import { Transcription } from 'models/transcription';

import { api } from 'store/service';

export interface MakePaymentRequest {
  amount: number;
}

interface MakePaymentResponse {
  link: string;
}

export interface PayTranscriptionRequest {
  transcriptionId: Transcription['id'];
}

interface PayTranscriptionResponse {
  status: 'need_payment' | 'paid';
  link?: string;
}

export const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentStatus: builder.query<PaymentStatus, string>({
      query: (code) => ({
        url: paths.paymentStatus(code),
      }),
      transformResponse: (response: SuccessResponse<PaymentStatus>) => response.data,
    }),
    makePayment: builder.mutation<MakePaymentResponse, MakePaymentRequest>({
      query: ({ amount }) => ({
        url: paths.makePayment(),
        method: 'POST',
        body: { amount },
      }),
      transformResponse: (response: SuccessResponse<MakePaymentResponse>) => response.data,
      invalidatesTags: (result) => (result ? ['User', 'BillingPayments'] : []),
    }),
    payTranscription: builder.mutation<PayTranscriptionResponse, PayTranscriptionRequest>({
      query: ({ transcriptionId }) => ({
        url: paths.payTranscription(transcriptionId),
        method: 'POST',
      }),
      transformResponse: (response: SuccessResponse<PayTranscriptionResponse>) => response.data,
      invalidatesTags: (result) => (result ? ['User', 'BillingPayments'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPaymentStatusQuery, useMakePaymentMutation, usePayTranscriptionMutation } = paymentsApi;

