import { notification } from 'antd';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { routes } from 'api/routes';

import { Spin } from 'shared/spin';

import { Container } from 'components/container';
import { Header } from 'components/header';

import { checkNotFoundError, getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';
import { useUser } from 'hooks/user';

import { useGetPaymentStatusQuery } from 'store/services/payments.service';

import { PaymentStatusIcon } from './payment-status.icon';
import { PaymentStatusInfo } from './payment-status.info';
import styles from './payment-status.page.module.scss';

export interface PaymentStatusPageProps {
  className?: string;
}

export function PaymentStatusPage({ className }: PaymentStatusPageProps) {
  const classes = cn(styles.paymentStatusPage, className);
  const params = useParams();
  const { userPermissions } = useUser();

  const paymentStatusCode = String(params.paymentStatusCode);

  const { data, isLoading, error, isError } = useGetPaymentStatusQuery(paymentStatusCode);
  useDocumentTitle('Информация о платеже');

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError && !checkNotFoundError(error)) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const interviewUrl: string | undefined = useMemo(() => {
    if (data?.data?.demo === undefined || !userPermissions) {
      return;
    }
    return routes.interview(userPermissions.demoInterview.projectId, userPermissions.demoInterview.interviewId);
  }, [data, userPermissions]);

  return (
    <div className={classes}>
      <Header />
      <Container className={styles.container}>
        {isLoading ? (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        ) : (
          <div className={styles.container}>
            <PaymentStatusIcon status={data?.status} />
            <PaymentStatusInfo status={data?.status} description={data?.description} interviewUrl={interviewUrl} />
          </div>
        )}
      </Container>
    </div>
  );
}

