/* eslint-disable react/display-name */
import cn from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, RenderElementProps, RenderLeafProps } from 'slate-react';

import { PlayerCurrentTime, PlayerProgress } from 'components/player';

import useQuestions from 'hooks/questions';

import { FileRelatedId, FileRelatedType } from 'models/file';
import { Question } from 'models/question';
import { Transcription, TranscriptionChannel } from 'models/transcription';

import { EditorElement } from './editor.element';
import { EditorLeaf } from './editor.leaf';
import styles from './editor.module.scss';
import QuestionMarksFields from './editor.question-marks-fields';
import { QuestionsPopup } from './editor.questions-popup';
import { EditorToolbar } from './editor.toolbar';
import { QuestionsList } from './editor.types';

export interface EditorFileProps {
  fileType: FileRelatedType;
  fileId?: FileRelatedId;
}

export interface EditorProps extends EditorFileProps {
  className?: string;
  rich?: boolean;
  isInterview?: boolean;
  initialValue?: string;
  initialNodes?: Descendant[];
  onChange?: (value: string) => void;
  onChangeNodes?: (value: Descendant[]) => void;
  questionsList?: Question[];
  interviewItemKey?: number;
  onReplicaPlayClick?: (currentTime: PlayerCurrentTime) => void;
  currentTime?: PlayerCurrentTime;
  channels?: TranscriptionChannel[];
  onChangeChannels?: (channels: TranscriptionChannel[]) => void;
  onRemoveTranscription?: (transcription: Transcription) => void;
  onDownloadTranscription?: (transcription: Transcription) => void;
  isDownloadingTranscription?: boolean;
  onPayTranscription?: (transcription: Transcription, callback: () => void) => void;
  isPayingTranscription?: boolean;
  readOnly?: boolean;
  activeChannelIds?: Set<TranscriptionChannel['id']>;
}

export function Editor({
  className,
  rich,
  isInterview,
  initialValue,
  onChange,
  initialNodes,
  onChangeNodes,
  questionsList,
  channels,
  interviewItemKey,
  onChangeChannels,
  onRemoveTranscription,
  onDownloadTranscription,
  isDownloadingTranscription,
  onPayTranscription,
  isPayingTranscription,
  readOnly,
  activeChannelIds,
  onReplicaPlayClick,
  currentTime,
}: EditorProps) {
  const classes = cn(styles.editor, className, {
    [styles.editor_rich]: rich,
    [styles.editor__interview]: isInterview,
  });
  const editableClasses = cn(styles.editor__wrapper, {
    [styles.editor__interview_wrapper]: isInterview,
  });

  const [editor] = useState(() => withHistory(withReact(createEditor())));
  const [playerProgress, setPlayerProgress] = useState<PlayerProgress | undefined>();
  const [nodes, setNodes] = useState<Descendant[]>([]);

  useEffect(() => {
    editor.onChange();
  }, []);

  const debouncedChangeNodes = debounce(setNodes, 500);

  const { questionMarks, questions, setQuestion } = useQuestions({
    nodes,
    editor,
    questionsList: questionsList || [],
  });

  const onProgressChange = (progress: PlayerProgress) => {
    setPlayerProgress(progress);
  };

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <EditorElement
        {...props}
        currentTime={currentTime}
        onReplicaPlayClick={onReplicaPlayClick}
        playerProgress={playerProgress}
        onProgressChange={onProgressChange}
        channels={channels}
        onChangeChannels={onChangeChannels}
        onRemoveTranscription={onRemoveTranscription}
        onDownloadTranscription={onDownloadTranscription}
        isDownloadingTranscription={isDownloadingTranscription}
        onPayTranscription={onPayTranscription}
        isPayingTranscription={isPayingTranscription}
        activeChannelIds={activeChannelIds}
      />
    ),
    [
      playerProgress,
      channels,
      onRemoveTranscription,
      onDownloadTranscription,
      isDownloadingTranscription,
      onPayTranscription,
      isPayingTranscription,
      activeChannelIds,
    ],
  );

  const value: Descendant[] = useMemo(
    () => (initialValue ? JSON.parse(initialValue) : DEFAULT_EDITOR_VALUE),
    [initialValue],
  );

  const renderLeafHoc = React.useCallback(
    (questions: QuestionsList) => (props: RenderLeafProps) => <EditorLeaf {...props} questions={questions} />,
    [],
  );

  if (initialValue === undefined && initialNodes === undefined) {
    return null;
  }

  const valueData: Descendant[] = initialNodes || value;

  return (
    <div data-onboarding='editor' className={classes}>
      <Slate
        key={interviewItemKey}
        editor={editor}
        value={valueData}
        onChange={(updatedValue) => {
          const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
          debouncedChangeNodes(updatedValue);
          if (isAstChange && onChange) {
            onChange(JSON.stringify(updatedValue));
          }
          if (isAstChange && onChangeNodes) {
            onChangeNodes(updatedValue);
          }
        }}
      >
        {rich && <EditorToolbar />}
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeafHoc(questions)}
          className={editableClasses}
          id='editor-box'
          readOnly={readOnly}
        />
        {isInterview && (
          <>
            <QuestionMarksFields questions={questions} questionsToPath={questionMarks} />
            <QuestionsPopup questionsList={questions} setQuestion={setQuestion} />
          </>
        )}
      </Slate>
    </div>
  );
}

export const DEFAULT_EDITOR_VALUE: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

