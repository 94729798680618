import React from 'react';

import { Text } from 'shared/typography';

import { declOfNum } from 'helpers/decl-of-num';
import { convertPricePerMinute } from 'helpers/price-converter';
import { formatPricePerHour, formatPricePerMinute } from 'helpers/price-formatter';

import { TariffItem as ITarifItem } from 'models/tariff';

import styles from './tariff.module.scss';

type TimeFormat = 'minute' | 'hour';

export interface TariffItemProps {
  tariffItem: ITarifItem;
  timeFormat: TimeFormat;
}

export function TariffItem({ tariffItem, timeFormat }: TariffItemProps) {
  return (
    <li className={styles.listItem}>
      <span>{tariffItem.name}</span>
      <strong>
        {tariffItem.minutes} {declOfNum(tariffItem.minutes, ['минута', 'минуты', 'минут'])}
      </strong>
      <Text type='secondary'>({getPriceTextByTimeFormat(tariffItem.price, timeFormat)})</Text>
    </li>
  );
}

function getPriceTextByTimeFormat(price: number, timeFormat: TimeFormat): string {
  switch (timeFormat) {
    case 'minute':
      return formatPricePerMinute(convertPricePerMinute(price));
    case 'hour':
      return formatPricePerHour(price);
    default:
      return '';
  }
}

